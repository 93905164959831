import React from "react";
import "./App.css";
import arrow from './assets/arrow-link.png'
import photo from './assets/my-photo.jpg'


function App() {

  const aboutMeText = `Привет, меня зовут Никита Вторушин и я технический разработчик продуктов из Сибири. 
  За годы работы я понял, что помимо написания кода мне интересно управлять продуктом. 
  Люблю процессы, цифры и факты.
  `

  const works = {
    og1: `Создание онлайн-образования по всему миру`,
    sw: `Управление командой из 10+ человек (В пике число доходило до 30+), тесное взаимодействие с владельцем высоконагруженного финтех продукта,
    внедрение фич в продукт, проектирование, ревью. Разработка новых продуктов, создание новых направлений внутри компании, R&D.`,
    freelance: `Разнорабочий, работа над крупным казино, c# продукты, интеграция с играми через ПО для ведения статистики. Реверсинг, пинтест`
  }

  const projects = {
    egal: `Разработка первой бета-версии фреймворка генерации интерфейса. Идея состояла в том, чтобы простые сервисы по типу
    административной панели или табличных интерфейсов - строились быстро без нужды вмешательства для создания новой таблицы от frontend разработчика.
    Первая версия так далеко зашла, что последующие версии имеют большое отличие и начинку, которая позволяет с использование Egal создавать сервисы
    любой сложности. Частично принимал участие в старте v2 версии`,
    bot: `Разработка backend части проекта (Проектирование, анализ, курирование, ревью), разработка универсального интеграционного модуля учета товаров.`,
    dao: `Разработка DAO контракта с интерфейсом управления и статистикой. Создание приватной сети и чейн explorer. Создание и анализ торгового бота на DEX площадках`,
    podcast: `Голые люди - это серия интервью с разными людьми, которые рассказывают о своих жизненных историях, достижениях, трудностях и проблемах. Беседуем с представителями различных профессий, которые сделали карьеру и носят гордое звание профессионалов своего дела. Мы совместно с гостями разобьем все стереотипы!`,
    TCAirSoft: `Калькулятор расчета скорости и энергии шара для страйкбола`
  }

  return (
      <div data-rk="">
        <div className="css-g0ck5h">
          <div className="css-1u0o7j0">
            <div className="css-zd4of5">
              <button type="button" className="css-1i0czjh">
                <div className="css-4bwcy0">
                  <img alt="Vtorushin" src={photo} className="chakra-image css-bqhrlt" />
                </div>
              </button>
            </div>
            <div className="css-rklm6r">
              <div className="css-0">
                <h1 className="chakra-heading css-19khvfy">Nikita Vtorushin</h1>
                <p className="chakra-text css-0">Product-focused Lead Developer</p>
              </div>
            </div>
          </div>
          <div className="css-1vhduvi">
            <h2 className="chakra-heading css-f48c4t">Обо мне</h2>
            <p className="chakra-text css-0">{aboutMeText}</p>
          </div>
          <div className="css-1vhduvi">
            <h2 className="chakra-heading css-16locuo">Work Experience</h2>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">2022 - present</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://og1.ru">Senior Frontend Lead Engineer – OG1
                  <img alt="Senior Frontend Lead Engineer – OG1" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
                <p className="chakra-text css-g5qhb2">{works.og1}</p>
                <p className="chakra-text css-y83l2k">TS • Next • React • Antd • React Native • Electron</p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">2019 - 2022</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://smartworld.team/">Lead Frontend & CPO & Lead Mobile –
                  SmartWorld
                  <img alt="Senior Frontend – SW" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
                <p className="chakra-text css-g5qhb2">{works.sw}</p>
                <p className="chakra-text css-y83l2k">Vue • React/React Native • Nuxt • Nest • Go • Solidity • TS</p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">2017 – 2020</p>
              </div>
              <div className="css-14l6pud">
                <h3 className="chakra-heading css-u14vgh">Front End Engineer – Freelance
                </h3>
                <p className="chakra-text css-g5qhb2">{works.freelance}</p>
                <p className="chakra-text css-y83l2k">Vue • React • C# • C++ • JS • TS • NodeJS • JAVA • Go</p>
              </div>
            </div>
            <h2 className="chakra-heading css-jtc8nn">Side Projects</h2>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">ongoing</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://podcast.vns.guru">Подкаст "Голые Люди"
                  <img alt="link to podcast" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
                  <p className="chakra-text css-g5qhb2">
                    {projects.podcast}
                  </p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">ongoing</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://apps.rustore.ru/app/com.example.tuningcontrol">Калькулятор дульной энергии [RuStore ANDROID]
                  <img alt="link to podcast" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
                  <p className="chakra-text css-g5qhb2">
                    {projects.TCAirSoft}
                  </p>
                <p className="chakra-text css-y83l2k">Kotlin • Compose</p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">2021</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://github.com/egal/">Egal Framework v1
                  <img alt="link to Egal Framework" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
                  <p className="chakra-text css-g5qhb2">
                    {projects.egal}
                  </p>
                  <p className="chakra-text css-y83l2k">Vue • TS • PHP</p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">2022</p>
              </div>
              <div className="css-14l6pud">
                  <a className="chakra-heading css-u14vgh" href="https://smartworld.team/cases/loftshop_telegram/">LoftShop Telegram bot
                    <img alt="link to loftShop" src={arrow} className="chakra-image css-1dtrd5g" />
                  </a>
                  <p className="chakra-text css-g5qhb2">
                    {projects.bot}
                  </p>
                  <p className="chakra-text css-y83l2k">Vue • NestJS • TS</p>
              </div>
            </div>
            <div className="css-1o42c8k">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">Ongoing</p>
              </div>
              <div className="css-14l6pud">
                <h3 className="chakra-heading css-u14vgh">
                  NDA
                </h3>
                  <p className="chakra-text css-g5qhb2">
                    {projects.dao}
                  </p>
                  <p className="chakra-text css-y83l2k">React • Solidity • TS</p>
              </div>
            </div>
            <h2 className="chakra-heading css-jtc8nn">Links</h2>
            <div className="css-129a06e">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">Github</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://github.com/vns0">@vns0
                  <img alt="link to @vns0" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
              </div>
            </div>
            <div className="css-129a06e">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">Telegram</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://t.me/nvtorushin">@nvtorushin
                  <img alt="link to @nvtorushin" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
              </div>
            </div>
            <div className="css-129a06e">
              <div className="css-zy08ml">
                <p className="chakra-text css-y83l2k">Career habr</p>
              </div>
              <div className="css-14l6pud">
                <a className="chakra-heading css-u14vgh" href="https://career.habr.com/nvtorush1n">@nvtorush1n
                  <img alt="link to @nvtorush1n" src={arrow} className="chakra-image css-1dtrd5g" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
